<template>
  <Editor
    v-if="activeEditor"
    :value="value"
    api-key="mgg0srn89nwzbyr2g5bl281e4vt12sb5ksishvboxuur4at7"
    :init="{
      valid_styles: { '*': 'color,font-weight,font-style,text-decoration' },
      branding: false,
      menubar: false,
      placeholder: placeholder,
      statusbar: false,
      height: 200,
      max_height: 400,
      toolbar: toolbarConfig,
      plugins: pluginsConfig,
      content_style:
        'body { line-height: 1;font-size: 14px }' +
        ' .mention{background-color: #c6d1da; padding: 1px 6px;border-radius: 8px;font-weight: 500;margin-right: 4px}',
      mentions_fetch: (query, success) => mentionsFetch(query, success),
      mentions_selector: 'span.mention',
      mentions_menu_complete: (editor, userInfo) => {
        const span = editor.getDoc().createElement('span');
        span.className = 'mention';
        span.setAttribute('data-mention-id', userInfo.id);
        span.appendChild(editor.getDoc().createTextNode('@' + userInfo.name));
        return span;
      },
    }"
    :disabled="status === 'Erledigt'"
    @input="emitInputEvent"
  />
  <b-skeleton v-else width="100%" height="200px"></b-skeleton>
</template>
<script>
import Editor from '@tinymce/tinymce-vue';
import { mapState } from 'vuex';

export default {
  components: { Editor },
  props: {
    value: { type: String },
    activeEditor: { type: Boolean, default: true },
    status: { type: String },
    disableTable: { type: Boolean, default: false },
    placeholder: { type: String },
    disableMentions: { type: Boolean, default: false },
  },
  computed: {
    ...mapState({
      users: state => state.users.users,
    }),
    pluginsConfig() {
      return this.allPlugins.filter(plugin => {
        if (plugin === 'table' && this.disableTable) {
          return false;
        }
        if (plugin === 'mentions' && this.disableMentions) {
          return false;
        }
        return true;
      });
    },
  },
  data() {
    return {
      toolbarConfig: 'undo redo | bold italic underline forecolor | bullist numlist | table ',
      allPlugins: ['table', 'lists', 'autoresize', 'mentions'],
    };
  },
  methods: {
    emitInputEvent(value) {
      this.$emit('input', value);
    },
    mentionsFetch(query, success) {
      const filteredUsers = this.users
        .filter(user => user.department !== null && user.givenName !== null && user.surname !== null)
        .map(user => ({
          id: user.id,
          name: user.givenName + ' ' + user.surname,
          surname: user.surname,
          givenName: user.givenName,
        }))
        .filter(
          user =>
            user.givenName.toLowerCase().startsWith(query.term.toLowerCase()) ||
            user.surname.toLowerCase().startsWith(query.term.toLowerCase())
        );
      success(filteredUsers);
    },
  },
};
</script>
