var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.activeEditor)?_c('Editor',{attrs:{"value":_vm.value,"api-key":"mgg0srn89nwzbyr2g5bl281e4vt12sb5ksishvboxuur4at7","init":{
    valid_styles: { '*': 'color,font-weight,font-style,text-decoration' },
    branding: false,
    menubar: false,
    placeholder: _vm.placeholder,
    statusbar: false,
    height: 200,
    max_height: 400,
    toolbar: _vm.toolbarConfig,
    plugins: _vm.pluginsConfig,
    content_style:
      'body { line-height: 1;font-size: 14px }' +
      ' .mention{background-color: #c6d1da; padding: 1px 6px;border-radius: 8px;font-weight: 500;margin-right: 4px}',
    mentions_fetch: (query, success) => _vm.mentionsFetch(query, success),
    mentions_selector: 'span.mention',
    mentions_menu_complete: (editor, userInfo) => {
      const span = editor.getDoc().createElement('span');
      span.className = 'mention';
      span.setAttribute('data-mention-id', userInfo.id);
      span.appendChild(editor.getDoc().createTextNode('@' + userInfo.name));
      return span;
    },
  },"disabled":_vm.status === 'Erledigt'},on:{"input":_vm.emitInputEvent}}):_c('b-skeleton',{attrs:{"width":"100%","height":"200px"}})
}
var staticRenderFns = []

export { render, staticRenderFns }